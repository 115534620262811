import React, { useState } from "react";
import { Link } from "gatsby";
import { FiCheck } from "react-icons/fi";
import MineGoogleMap from "../../components/info/MineGoogleMap";
import TicInfoBox from "./TicInfo";
import ZkmsInfoBox from "./ZkmsInfoBox";
import ContactForm from "./ContactForm";
import MuseumBox from "./MuseumBox";

function CompanyInfo(props) {
    const [messageSent, setMessageSend] = useState(false);

    return (
        <div className="rn-contact-top-area ptb--120 bg_color--3">
            <div className="container">
                <div className="row ">
                    <div className="col-12 col-lg-8 col-xl-8 pr-lg-5">
                        <div className="">
                            <div className="section-title mb-5">
                                <h2 className="title">Rezervacija</h2>

                                {messageSent ? (
                                    <p>
                                        Hvala za vaše sporočilo. Odgovorili vam
                                        bomo v najkrajšem možnem času.
                                    </p>
                                ) : (
                                    <ContactForm
                                        setFormSuccessStatus={(value) =>
                                            setMessageSend(value)
                                        }
                                    />
                                )}
                            </div>

                            <div className="section-title">
                                <h2 className="title">Kdo smo?</h2>
                                <p className="description">
                                    Turistično informacijski center (TIC) nudi
                                    informacije o{" "}
                                    <a
                                        href="https://visitlitija.si"
                                        target="_blank"
                                    >
                                        turistični ponudbi v Litiji in okolici
                                    </a>
                                    . TIC je vstopna točka za Rudnik Sitarjevec
                                    Litija. Rudnik lahko obiščete po predhodni
                                    najavi od četrtka do nedelje, večje skupine
                                    po dogovoru. TIC organizira turistične
                                    izlete za prihajajoče goste. V TIC Litija
                                    deluje z lokalnimi produkti lepo založena
                                    trgovinica, ki so primerni tudi kot spominki
                                    ali simbolična darila. Izbirate lahko med
                                    unikatnimi lesenimi, keramičnimi,
                                    pletarskim, tekstilnimi izdelki, različnim
                                    nakitom, naravnimi mili, čaji in drugimi
                                    unikatnimi rokodelskimi izdelki.
                                </p>

                                <div className="description mb--40">
                                    <div className="mb-2">
                                        V TIC vas pričakujemo v naslednjem
                                        delovnem času:{" "}
                                    </div>

                                    <ul className="list-style--1 pl-2">
                                        <li>
                                            <FiCheck />
                                            <strong>ponedeljek</strong> zaprto
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>torek</strong> 10:00 do
                                            16:00
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>sreda</strong> 9:00 do 17:00
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>četrtek</strong> zaprto
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>petek</strong> 9:00 do 15:00
                                        </li>
                                        <li>
                                            <FiCheck />
                                            <strong>sobota</strong> 9:00 do
                                            12:00
                                            <div className="saturday-visit-text saturday-special-offer">
                                                Prisotni na lokaciji recepcije rudnika.
                                            </div>
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>
                                                nedelja in prazniki
                                            </strong>{" "}
                                            zaprto
                                        </li>
                                    </ul>
                                </div>

                                <div className="description mb--40">
                                    <div className="mb-2">
                                        Urnik ogledov Rudnika Sitarjevec Litija
                                        je naslednji:{" "}
                                    </div>

                                    <ul className="list-style--1 pl-2">
                                        <li>
                                            <FiCheck />
                                            <strong>četrtek</strong> 9:00 do
                                            12:00 in 15:00 do 18:00
                                        </li>

                                        <li>
                                            <FiCheck />
                                            <strong>petek</strong> 9:00 do 12:00
                                            in 15:00 do 18:00
                                        </li>
                                        <li>
                                            <FiCheck />
                                            <strong>sobota</strong> 9:00 do
                                            12:00 in 15:00 do 18:00
                                            <div className="saturday-visit-text saturday-special-offer">
                                                Vsako soboto ob 10:00 možnost ogleda Izvoznega rova rudnika brez predhodne najave!
                                            </div>
                                        </li>
                                        <li>
                                            <FiCheck />
                                            <strong>nedelja</strong> 9:00 do
                                            12:00 in 15:00 do 18:00
                                        </li>
                                    </ul>

                                    <small className="mt--20 d-inline-block">
                                        *Za obisk rudnika je potrebno predhodno
                                        naročilo. Skupine je možno naročiti tudi
                                        izven navedenih ur.
                                    </small>
                                    <small className="d-inline-block">
                                        *Cenik ogledov in ostalih storitev lahko
                                        najdete <Link to="/cenik">tukaj</Link>.
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="section-title">
                                <div className="description">
                                    <MineGoogleMap
                                        address={{
                                            lat: 46.05727097270354,
                                            lng: 14.831443234246294,
                                        }}
                                        googleApiKey="AIzaSyD_4eDYM8ZTWDEFoemtRivWJBYOmlHTNUU"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 col-xl-4">
                        <TicInfoBox />
                        <ZkmsInfoBox />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyInfo;
